<template>
	<div>
		<common-table
				:itemScope="['status']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				ref="ct"
				:customHtml="true"
				@remove="remove"
				pageTitle="意见反馈"
				pageKey="sqlid"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
		>
			<template v-slot:customHtml>
				<advanced-search
						:fixedConfig="leftConfig"
						:othersConfig="othersConfig"
						@search="search"
						:hasExportFile="true"
						@exportFile="exportFile"
				/>
			</template>
			<template v-slot:status="{ item }">
				<template v-if="item.status === 1">
					<span style="color: #11B211">已回复</span>
				</template>
				<template v-else>
					<span style="color: #F25757">未回复</span>
				</template>
			</template>
		</common-table>
		<template v-if="dialog">
			<exhibition-data-box :width="936" :contentHeight="500" :dialog.sync="dialog" title="">
				<div style="display: flex; justify-content: space-evenly">
					<div style="width: 410px">
						<strong style="font-size: 18px;">反馈详情</strong>
						<v-form class="d-flex flex-wrap mt-2">
							<v-col cols="6">
								<v-text-field
										autocomplete="off"
										hide-details
										dense
										label="编号"
										readonly
										v-model="sform.regno"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
										autocomplete="off"
										hide-details
										dense
										readonly
										label="姓名"
										v-model="sform.name"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
										autocomplete="off"
										hide-details
										dense
										label="电话"
										readonly
										v-model="sform.tel"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
										autocomplete="off"
										hide-details
										readonly
										dense
										label="反馈点位"
										v-model="sform.place"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
										autocomplete="off"
										hide-details
										readonly
										dense
										label="反馈类型"
										v-model="sform.typedesc"
								>
								</v-text-field>
							</v-col>
							<v-col cols="12">
								<v-textarea
										auto-grow
										solo
										readonly
										outlined
										flat
										v-model="sform.feedbackinfo"
								>
								</v-textarea>
							</v-col>
							<div class="d-flex" style="gap: 10px; align-items: center">
								<image-view :imgList="sform.picurl.split(',')" v-if="sform.picurl"/>
								<div class="ml-4 text-center" v-if="sform.audiourl">
									<div>
										<v-icon v-if="!isPlay" @click="handlePlayAudio" size="48" color="primary" class="iconfont">iconfont icon-bofang-fill</v-icon>
										<v-icon v-else @click="handleStopAudio" size="48" color="primary" class="iconfont">iconfont icon-zanting-fill</v-icon>
										<audio ref="audio" :src="sform.audiourl" @ended="audioEnd"/>
									</div>
									<div style="font-size: 14px; color: #666666">反馈音频</div>
								</div>
							</div>
						</v-form>
					</div>
					<div style="background: #d8d8d8; width: 1px; height: 340px"></div>
					<div style="width: 410px">
						<strong style="font-size: 18px;">回复详情</strong>
						<v-form class="d-flex flex-wrap mt-2">
							<v-col cols="6">
								<v-text-field
										autocomplete="off"
										hide-details
										dense
										readonly
										label="回复人"
										v-model="sform.handler"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
										autocomplete="off"
										hide-details
										dense
										readonly
										label="回复时间"
										v-model="sform.handletime"
								>
								</v-text-field>
							</v-col>
							<v-col cols="12">
								<v-textarea
										auto-grow
										solo
										outlined
										flat
										v-model="sform.handleinfo"
								>
								</v-textarea>
							</v-col>
							<v-col cols="12">
								<w-autocomplete
										placeholder="宾客意见分类" @change="sform.department = $event" :value="sform.department"
										:items="departmentList"/>
							</v-col>
						</v-form>
					</div>
				</div>
				<template v-slot:footer>
					<div class="text-md-center">
						<v-btn
								depressed
								color="primary"
								width="72"
								outlined
								@click="sform.handleinfo = ''"
						>
							重置
						</v-btn>
						<v-btn
								class="ml-6"
								depressed
								color="primary"
								width="72"
								:loading="loading"
								@click="validateSave"
						>
							提交
						</v-btn>
					</div>
				</template>
			</exhibition-data-box>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/orderManage/feedback.js';
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import imageView from "@/components/image-view/index.vue";
import advancedSearch from "@/components/advancedSearch/advancedSearch.vue";
import WAutocomplete from "../../../components/w-autocomplete/w-autocomplete.vue";

export default {
	// 意见反馈
	components: {WAutocomplete, exhibitionDataBox, imageView, advancedSearch},
	mixins: [commonCurd],
	data() {
		return {
			api,
			loading: false,
			sform: {
				regno: '',
				name: '',
				tel: '',
				place: '',
				feedbackinfo: '',
				picurl: '',
				handletime: '',
				handleinfo: '',
				handler: '',
				department: ''
			},
			dialog: false,
			btnActionEvent: [
				{
					event: 'getDetails',
					icon: 'chakan-fill',
					color: 'primary',
					tooltip: '跟进详情'
				},
				{
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除'
				}
			],
			headers: [
				{text: '编号', value: 'regno'},
				{text: '姓名', value: 'name'},
				{text: '电话', value: 'tel'},
				{text: '反馈类型', value: 'typedesc'},
				{text: '反馈内容', value: 'feedbackinfo', width: '400px'},
				{text: '状态', value: 'status'},
				{text: '跟进人', value: 'handler'},
				{text: '创建时间', value: 'createtime'},
				{text: '操作', value: 'actions', width: '80px'},
			],
			leftConfig: [
				{type: 'input', label: '输入电话号码', property: 'tel'},
				{type: 'input', label: '输入跟进人', property: 'handler'},
				{
					type: 'select',
					label: '反馈类型',
					property: 'type',
					items: [{code: 'ADVICE', desc: '服务建议'}, {code: 'COMPLAINTS', desc: '投诉举报'}]
				},
			],
			othersConfig: [
				{type: 'rangeTime', label: '申请时间', property1: 'starttime', property2: 'endtime'}
			],
			departmentList: [],
			isPlay: false
		}
	},
	created() {
		this.axios.post(this.select_data, {keyname: 'department'}).then(res => {
			this.departmentList = res.data[0].values
		})
	},
	methods: {
		// 点击喇叭图标, 开始播放音频
		handlePlayAudio() {
			this.$refs.audio && this.$refs.audio.play()
			this.isPlay = true
		},
		handleStopAudio() {
			this.$refs.audio && this.$refs.audio.pause()
			this.isPlay = false
		},
		audioEnd() {
			this.isPlay = false
		},
		async otherTableOperate(e, item) {
			this.axios.post(this.api.details, {sqlid: item.sqlid}).then((res) => {
				if (res.code === this.staticVal.Code.Success) {
					this.sform = res.data
					this.sform.handler = this.sform.handler ? this.sform.handler : sessionStorage.getItem('userid');
					this.sform.handletime = this.sform.handletime ? this.sform.handletime : this.moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
					this.dialog = true;
				}
			});
		},
		validateSave() {
			if (this.sform.handleinfo) {
				this.loading = true
				this.axios.post(this.api.save, this.sform).then(r => {
					this.loading = false
					if (r.code === this.staticVal.Code.Success) {
						this.snackbar.success("跟进反馈成功");
						this.dialog = false
						this.list();
					}
				});
			} else {
				this.snackbar.success("请输入回复内容");
			}
		},
	},
	watch: {
		dialog: {
			handler(v) {
				if(!v) {
					this.handleStopAudio()
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">

</style>
