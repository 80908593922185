<template>
	<viewer :images="imgList" class="imageBox">
		<img
				v-for="(decImg, index) in imgList"
				:key="index"
				alt=""
				:src="decImg"
				:style="{'border-radius': borderRadius, width: width, height: height }"
		/>
	</viewer>
</template>
<script>
export default {
	name: 'VViewer',
	props: {
		imgList: Array,
		borderRadius: {
			// 图片的圆角效果
			type: String,
			default: '8px'
		},
		width: {
			type: String,
			default: '88px'
		},
		height: {
			type: String,
			default: '88px'
		}
	}
}
</script>
<style scoped lang="scss">
.imageBox {
	overflow: auto;
	display: flex;
	align-items: baseline;
	gap: 10px;
}

.imageBox img {
	width: 90%;
	height: 80%;
	margin: 5px 1%;
	cursor: zoom-in;
	object-fit: cover;
	overflow: hidden;
}
</style>
